/* theme css */
*,
:after,
:before {
  box-sizing: inherit;
}
body,
html {
  background-color: #140c40;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  box-sizing: border-box;
}
body {
  color: #140c40;
  font-family: Basis Grotesque, Helvetica, sans-serif;
  -webkit-font-feature-settings: "salt";
  font-feature-settings: "salt";
  font-variant-numeric: tabular-nums;
  background-color: #fffefe;
}
img,
svg {
  display: block;
  max-width: 100%;
  height: auto;
}
a {
  color: inherit;
  text-decoration: none;
}
div:focus,
a:focus,
button:focus {
  outline: 0;
}
.body-xs,
.list.--small .list__item,
.post-lockup__action,
.quote__byline,
.space__address,
.space__name,
.stat__attribution {
  font-family: Basis Grotesque, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 1.5;
}
.u-color-periwinkle {
  color: #4760ff;
}
blockquote,
h1,
h2,
h3,
h4,
h5,
h6,
ol,
p,
ul {
  -webkit-margin-after: 0;
  margin-block-end: 0;
  -webkit-margin-before: 0;
  margin-block-start: 0;
  -webkit-margin-start: 0;
  margin-inline-start: 0;
  -webkit-margin-end: 0;
  margin-inline-end: 0;
  -webkit-padding-start: 0;
  padding-inline-start: 0;
}
ul {
  list-style-type: none;
  padding-left: 0;
}
@font-face {
  font-family: Basis Grotesque;
  src: url(/fonts/basis-grotesque-regular-pro.woff) format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: Basis Grotesque;
  src: url(/fonts/basis-grotesque-medium-pro.woff) format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: Basis Grotesque;
  src: url(/fonts/basis-grotesque-bold-pro.woff) format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: Simula;
  src: url(/fonts/Simula-Book.woff) format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: block;
}
/* header css */
.site-header {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 7.5%;
  background-color: transparent;
  z-index: 2;
  transition: -webkit-transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  transition: transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  transition: transform 0.4s cubic-bezier(0.19, 1, 0.22, 1), -webkit-transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  z-index: 999;
  /* height: 84px; */
  pointer-events: none;
}
/* .site-header.--has-alert:not(.--is-toggled):not(.--is-stuck) {
  -webkit-transform: translateY(50px);
  transform: translateY(50px);
} */
.site-header__inner {
  max-width: 1200px;
  margin: auto;
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 30px;
}
.site-header__logo {
  width: 40%;
  max-width: 130px;
  position: relative;
  z-index: 2;
  /* margin-top: 72px; */
  pointer-events: all;
  color: #140c40;
}
.site-header__nav {
  width: 66.666%;
  display: flex;
  flex: auto;
  justify-content: flex-end;
}
.nav-primary {
  font-size: 16px;
  display: flex;
  align-items: center;
  pointer-events: all;
  /* margin-top: 70px; */
}
.nav-primary__main {
  transition: opacity 0.2s cubic-bezier(0.19, 1, 0.22, 1);
}
.nav-primary__links {
  display: flex;
  align-items: baseline;
}
.nav-primary__link {
  margin-right: 20px;
}
.nav-primary__link > a {
  font-weight: 600;
  transition: border 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  border-bottom: 1px solid transparent;
  padding: 2px 0;
}
.nav-primary__toggle {
  position: relative;
  height: 18px;
  width: 21px;
  cursor: pointer;
  display: flex;
  align-items: center;
  z-index: 2;
}
.nav-primary__toggle > span {
  height: 2px;
  width: 100%;
  background-color: #140c40;
  display: inline-block;
  transition: background-color 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}
.nav-primary__link:last-child {
  margin-right: 0;
}
.nav-primary__toggle > span:after,
.nav-primary__toggle > span:before {
  content: "";
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background-color: #140c40;
  left: 0;
  transition: background-color 0.3s cubic-bezier(0.19, 1, 0.22, 1),
    -webkit-transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  transition: transform 0.3s cubic-bezier(0.19, 1, 0.22, 1), background-color 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  transition: transform 0.3s cubic-bezier(0.19, 1, 0.22, 1), background-color 0.3s cubic-bezier(0.19, 1, 0.22, 1),
    -webkit-transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}
.nav-primary__toggle > span:before {
  top: 0;
}
.nav-primary__toggle > span:after {
  bottom: 0;
}
.site-header.--is-toggled:not(.--is-stuck) {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  transition: none;
}
.site-header.--is-toggled {
  position: fixed;
  top: 0;
  transition: none;
}
/* footer css */
.site-footer {
  background-color: #0f0833;
  padding: 80px 0 40px;
}
.site-footer__inner {
  max-width: 1200px;
  width: 90%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
}
.site-footer__top {
  width: 100%;
  margin-bottom: 50px;
}
.site-footer__links {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.site-footer__links-section {
  width: 50%;
  padding-right: 10px;
}
.site-footer__links-section.--wide {
  width: 100%;
}
.site-footer__blog-link {
  margin-top: 8px;
  display: block;
}
.f-16 {
  color: #fffefe;
  font-size: 16px !important;
  line-height: 1.6;
}
.site-footer__links-section-header {
  font-weight: 600;
}
.site-footer__link {
  font-size: 16px;
  margin-top: 10px;
  display: inline-block;
  width: 100%;
}
.site-footer__link > a {
  color: #fffefe;
  line-height: 1.6;
  border-bottom: 1px solid #140c40;
  transition: border 0.2s cubic-bezier(0.19, 1, 0.22, 1);
}
.site-footer__blog-and-social {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.site-footer__blog {
  width: 100%;
  margin-top: 50px;
}
.site-footer__blog-link {
  margin-top: 8px;
  display: block;
}
.site-footer__social {
  margin-top: 50px;
  width: 100%;
}
.icon-list {
  display: flex;
  align-items: center;
}
.icon-list.--social .icon-list__item {
  width: auto;
  height: 20px;
}
.icon-list__item {
  margin-right: 15px;
  margin-top: 15px;
}
.icon-list.--social .icon-list__item > svg {
  width: auto;
  height: 20px;
  max-width: unset;
}
.icon-list__item > svg {
  max-width: 20px;
  height: auto;
}
.site-footer__copyright {
  margin-top: 120px;
  width: 100%;
  opacity: 0.5;
}
.u-color-white {
  color: #fffefe;
}
.site-footer__link > a:hover {
  border-bottom-color: #5688f5;
}
.icon-list.--fill-white-hover-periwinkle .icon-list__item:hover > svg * {
  fill: #4760ff !important;
}

/* content css */
#main-content {
  outline: 0;
  min-height: 80vh;
}
.hero {
  display: flex;
  align-items: center;
  flex-direction: row;
  position: relative;
}
.hero.--no-padding-bottom .hero__inner {
  padding-bottom: 0;
}
.hero__inner {
  width: 90%;
  padding: 130px 0;
  max-width: 1200px;
  margin: auto;
}
.hero__heading {
  position: relative;
}
.heading-xl,
.text-lockup.--xlarge .text-lockup__heading {
  font-family: Basis Grotesque, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 40px;
  line-height: 1.1;
  letter-spacing: -0.0075em;
}
.u-text-align-center {
  text-align: center;
}
.btn {
  padding: 14px 20px;
  background-color: #4760ff;
  color: #fffefe;
  border-radius: 100px;
  border: none;
  display: inline-block;
  text-align: center;
  font-weight: 600;
  transition: -webkit-transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  transition: transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  transition: transform 0.3s cubic-bezier(0.19, 1, 0.22, 1), -webkit-transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  font-size: 16px;
  cursor: pointer;
  font-family: Basis Grotesque, Helvetica, sans-serif;
}
.nav-primary.--is-toggled .nav-primary__toggle > span {
  background-color: transparent;
}
.nav-primary.--is-toggled .nav-primary__toggle > span:before {
  -webkit-transform: rotate(45deg) translate3d(1px, -1px, 0);
  transform: rotate(45deg) translate3d(1px, -1px, 0);
}
.nav-primary.--is-toggled .nav-primary__toggle > span:after {
  -webkit-transform: rotate(-45deg) translate3d(1px, -1px, 0);
  transform: rotate(-45deg) translate3d(1px, -1px, 0);
  color: #140c40;
}
.hero__form {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 840px;
}
.floating-form {
  text-align: center;
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
}
.floating-form__header {
  margin-bottom: 20px;
}
.body-l,
.quote__text,
.text-btn,
.text-lockup.--how-to-home .text-lockup__body,
.text-lockup.--large .text-lockup__body,
.text-lockup.--xlarge .text-lockup__body {
  font-family: Simula, Times, serif;
  font-size: 21px;
  line-height: 1.4;
  font-weight: 400;
}
.floating-form__sub-heading {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 600;
}

.l-container.--extra-narrow {
  max-width: 920px;
}
.l-container {
  width: 85%;
  max-width: 600px;
  margin: auto;
}
.l-two-col {
  display: -ms-grid;
  display: grid;
  align-items: center;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  grid-column-gap: 15px;
  grid-row-gap: 50px;
}
.mapouter {
  position: relative;
  text-align: right;
  height: 386px;
  max-width: 482px;
  width: 100%;
}
.gmap_canvas {
  overflow: hidden;
  background: none!important;
  height: 386px;
  max-width: 482px;
  width: 100%;
}
#gmap_canvas {
  max-width: 482px;
  width: 100%;
}
.text-lockup__heading {
  margin-bottom: 0.25em;
}
.card__heading, .heading-s, .rich-text h2, .text-lockup__heading {
  font-family: Basis Grotesque, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 21px;
  line-height: 1.15;
}
.body-m, .card__body, .post-lockup__heading, .text-lockup.--featured-space .text-lockup__body, .text-lockup.--medium .text-lockup__body, .text-lockup__body {
  font-family: Simula, Times, serif;
  font-size: 18px;
  line-height: 1.333;
  font-weight: 400;
}
.body-m + p, .body-m p + p, .card__body + p, .card__body p + p, .post-lockup__heading + p, .post-lockup__heading p + p, .text-lockup.--featured-space .text-lockup__body + p, .text-lockup.--featured-space .text-lockup__body p + p, .text-lockup.--medium .text-lockup__body + p, .text-lockup.--medium .text-lockup__body p + p, .text-lockup__body + p, .text-lockup__body p + p {
  margin-top: 0.8em;
}
.text-lockup__body a {
  color: #4760ff;
}


/* number */
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type=number] {
    -moz-appearance:textfield;
}

.display-linebreak {
  white-space: pre-line;
}









/* media queries */
@media (max-width: 33.74em) {
  .nav-primary__link > a {
    font-size: 7vw;
  }
}

@media (min-width: 48.75em) {
  .text-lockup__heading {
    margin-bottom: 0.5em;
}
.body-m, .card__body, .post-lockup__heading, .text-lockup.--featured-space .text-lockup__body, .text-lockup.--medium .text-lockup__body, .text-lockup__body {
  font-size: 21px;
}
  .l-two-col {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
}
  .body-l,
  .quote__text,
  .text-btn,
  .text-lockup.--how-to-home .text-lockup__body,
  .text-lockup.--large .text-lockup__body,
  .text-lockup.--xlarge .text-lockup__body {
    font-size: 28px;
  }
  .hero__form {
    margin-top: 50px;
  }
  .btn {
    padding: 16px 22px;
  }
  .heading-xl,
  .text-lockup.--xlarge .text-lockup__heading {
    font-size: 64px;
  }
  .site-footer {
    padding: 120px 0 90px;
  }
  .body-xs,
  .list.--small .list__item,
  .post-lockup__action,
  .quote__byline,
  .space__address,
  .space__name,
  .stat__attribution {
    font-size: 15px;
  }
  .hero__inner {
    padding: 150px 50px;
  }
}

@media (min-width: 57.5em) {
  .site-footer__links {
    width: 75%;
    padding-right: 20px;
  }
  .site-footer__blog-and-social {
    width: 25%;
  }
  .site-footer__blog {
    margin-top: 0;
  }
}
@media (min-width: 35.625em) {
  .site-footer__links-section.--wide {
    width: 50%;
  }
  .site-footer__links-section {
    width: 25%;
  }
}

@media (min-width: 72.75em) {
  .site-header {
    padding: 0 40px;
    /* margin-top: -34px; */
  }
  .site-header__logo {
    -webkit-transform: translateY(-14%);
    transform: translateY(-14%);
  }
  .nav-primary__main {
    display: block;
  }
  .nav-primary__toggle {
    display: none;
  }
  .nav-primary__link > a:hover {
    border-bottom-color: #140c40;
  }
}
@media (min-width: 33.75em) {
  .heading-xl,
  .text-lockup.--xlarge .text-lockup__heading {
    font-size: 48px;
  }
  .body-l,
  .quote__text,
  .text-btn,
  .text-lockup.--how-to-home .text-lockup__body,
  .text-lockup.--large .text-lockup__body,
  .text-lockup.--xlarge .text-lockup__body {
    font-size: 24px;
  }
}
@media (min-width: 70.25em) {
  .l-two-col {
    grid-row-gap: 140px;
}
.card__heading, .heading-s, .rich-text h2, .text-lockup__heading {
  font-size: 28px;
}
.l-two-col {
  grid-column-gap: 45px;
}
  .l-container.--extra-narrow {
    width: 75%;
}
  .hero__form {
    margin-top: 60px;
  }
  .floating-form__header {
    margin-bottom: 30px;
  }
  .btn {
    padding: 18px 24px;
  }
  .heading-xl,
  .text-lockup.--xlarge .text-lockup__heading {
    font-size: 60px;
  }
  .body-xs,
  .list.--small .list__item,
  .post-lockup__action,
  .quote__byline,
  .space__address,
  .space__name,
  .stat__attribution {
    font-size: 16px;
  }
  .hero__inner {
    padding: 200px 100px;
  }
}

@media (max-width: 35.615em) {
  .site-footer__links-section + .site-footer__links-section {
    margin-top: 50px;
  }
}

@media (max-width: 57.49em) and (min-width: 38.75em) {
  .site-footer__social {
    width: 50%;
  }
  .site-footer__blog {
    width: 50%;
    padding-right: 50px;
  }
}

@media (min-width: 45em) {
  .site-header__logo {
    width: 33.333%;
  }
}

@media (max-width: 72.74em) and (min-width: 45em) {
  .nav-primary__toggle {
    height: 20px;
    width: 23px;
  }
}

@media (max-width: 72.74em) {
  .nav-primary__main {
    opacity: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    min-height: 100vh;
    background-color: #fffefe;
    z-index: 1;
  }
  .nav-primary.--is-toggled .nav-primary__main {
    opacity: 1;
    pointer-events: all;
  }
  .nav-primary__links {
    display: block;
    width: 100%;
    padding: 25% 7.5% 20px;
  }
  .nav-primary__link {
    padding: 10px 0;
    display: block;
    width: 100%;
  }
}

@media (max-width: 72.74em) and (min-width: 33.75em) {
  .nav-primary__link > a {
    font-size: 36px;
  }
}
@media (min-width: 48.75em) {
  .card__heading, .heading-s, .rich-text h2, .text-lockup__heading {
    font-size: 24px;
}
  .l-two-col {
    grid-row-gap: 120px;
}
.l-two-col.--reverse-desktop .l-two-col__col:nth-of-type(odd) {
  -ms-grid-column: 2;
  grid-column: 2;
  -ms-grid-row: 1;
  grid-row: 1;
}
.l-two-col.--reverse-desktop .l-two-col__col:nth-of-type(2n) {
  -ms-grid-column: 1;
  grid-column: 1;
  -ms-grid-row: 1;
  grid-row: 1;
}
  .l-container {
    max-width: 1200px;
    width: 85%;
}
  .body-l,
  .quote__text,
  .text-btn,
  .text-lockup.--how-to-home .text-lockup__body,
  .text-lockup.--large .text-lockup__body,
  .text-lockup.--xlarge .text-lockup__body {
    font-size: 28px;
  }
  .floating-form__sub-heading {
    font-size: 18px;
  }
}

.floating-form__field textarea.field {
  border-radius: 25px;
}
@media (min-width: 70.25em){
  .body-m, .card__body, .post-lockup__heading, .text-lockup.--featured-space .text-lockup__body, .text-lockup.--medium .text-lockup__body, .text-lockup__body {
    font-size: 24px;
}
.card__heading, .heading-s, .rich-text h2, .text-lockup__heading {
    font-size: 28px;
}
}
/* cyrillic-ext */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v2/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v2/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v2/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format("woff2");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v2/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v2/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v2/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v2/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v2/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v2/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v2/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format("woff2");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v2/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v2/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v2/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v2/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v2/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v2/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v2/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format("woff2");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v2/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v2/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v2/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v2/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.submit-req {
  text-align: center;
  margin-top: 25px;
}
.submit-req .btn {
  text-transform: uppercase;
  font-family: "Inter";
  font-weight: 400;
}
/* .custom-page-heading {
  font-family: "Inter";
  font-weight: 300;
  line-height: 58px;
} */

/* checkbox */

.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 14px;
  color: #909090;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  min-height: 20px;
}

.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkbox-container input:checked ~ .checkmark {
  background-color: #140c40;
  border: 1px solid #140c40;
}

.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-container .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -o-border-radius: 2px;
}

.checkbox-container .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 6px;
  top: 2px;
  width: 7px;
  height: 12px;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.custom-container {
  max-width: 1200px;
  width: 90%;
  margin: auto;
  position: relative;
}
.go-back {
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  font-size: 14px;
  position: absolute;
  top: 50px;
  left: 0;
  z-index: 1;
  display: block;
  padding: 8px 10px 7px;
  border: 2px solid #fff;
  border-radius: 25px;
  margin: 10px;
}
.go-back img {
  display: inline-block;
  vertical-align: middle;
  margin-top: -3px;
  margin-right: 5px;
  width: 18px;
  height: 18px;
}
.custom-row {
  margin-left: -15px;
  margin-right: -15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  box-sizing: border-box;
}
.custom-col-3 {
  padding-left: 15px;
  padding-right: 15px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  position: relative;
  width: 100%;
  min-height: 1px;
}
.custom-container-padding {
  padding: 50px 0;
}
.business-card {
  margin-bottom: 30px;
}
.business-card .business-card-image {
  border-radius: 5px;
  background-color: #f1f1f1;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 227px;
  margin-bottom: 15px;
}
.business-card .check-c {
  position: relative;
}
.business-card .check-c .checkbox-container {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}
.business-card .business-card-heading {
  margin-left: 40px;
  font-size: 18px;
  margin-bottom: 5px;
  font-family: Inter;
  font-weight: 500;
}
.business-card .business-card-location {
  margin-left: 40px;
  font-size: 14px;
  font-family: Inter;
  font-weight: 400;
}

.pagination {
  display: inline-block;
  margin: 15px 0;
}

.pagination button {
  color: #140c40;
  float: left;
  padding: 8px 10px;
  text-decoration: none;
  font-size: 14px;
  border: 0px;
  background-color: transparent;
  cursor: pointer;
  font-family: Inter;
  font-weight: 400;
  margin-right: 2px;
}

.pagination button.active {
  background-color: #140c40;
  color: white;
  border-radius: 5px;
}

.pagination button:hover:not(.active) {
  background-color: #ddd;
  border-radius: 5px;
}
.py-3 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.show-more-btn {
  background-color: #140c40;
  display: block;
  width: 100%;
  text-transform: uppercase;
  font-family: Inter;
  font-weight: 400;
}

.custom-hr {
  margin: 30px 0;
  box-shadow: 0 0 0 transparent;
  background: transparent;
  outline: 0;
  border: 0;
  border-bottom: 1px solid #ddd;
}
.business-sub-heading {
  margin-bottom: 15px;
  font-weight: 600;
  font-family: "Inter";
}
.business-para {
  margin-bottom: 15px;
  font-family: "Inter";
  font-size: 14px;
}
.category-container .category {
  display: inline-block;
  padding: 10px 25px;
  border: 1px solid #d2d2fb;
  border-radius: 15px;
  margin-right: 15px;
  margin-top: 15px;
  font-size: 14px;
  font-family: "Inter";
  color: #140c40;
  font-weight: 500;
}
.half-div {
  width: 50%;
  float: left;
}
.form-radio {
  margin-bottom: 20px;
}
.clearfix {
  clear: both;
}

.business-details {
  max-width: 500px;
}
.business-name {
  margin-bottom: 10px;
  font-size: 20px;
  font-family: "Inter";
  font-weight: 400;
}
.business-location {
  color: #655792;
  line-height: 20px;
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
}

.btn-request {
  width: 100%;
  display: inline-block;
  font-family: "Inter";
  font-weight: 400;
}
.selected-para {
  text-align: center;
  margin-top: 20px;
  color: #4760ff;
  font-family: "Inter";
  font-weight: 400;
}
.success-image img {
  margin: auto;
  height: 280px;
}
.btn-go-home {
  max-width: 500px;
  width: 100%;
  font-family: "Inter";
  margin: auto;
  font-weight: 400;
}

.go-home-c {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 100px;
  font-family: "Inter";
  font-weight: 400;
}
.form-div {
  max-width: 480px;
  margin: 50px auto 100px;
  font-family: "Inter";
  font-weight: 400;
}
.btn.btn-block {
  width: 100%;
}
.btn-submit-now {
  width: 100%;
  display: block;
  text-transform: uppercase;
  font-family: "Inter";
  font-weight: 400;
  margin-top: 40px;
}

.floating-form__field {
  margin-bottom: 15px;
}
.floating-form__field .field {
  width: 100%;
  background-color: #fffefe;
  box-shadow: inset 0 0 0 2px rgba(71, 96, 255, 0.25), 0 0 0 4px rgba(71, 96, 255, 0);
  border-radius: 60px;
  font-size: 16px;
  transition: background-color 0.3s cubic-bezier(0.19, 1, 0.22, 1), box-shadow 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: 0;
  border: none;
  resize: none;
  font-family: "Inter";
  padding: 16px 22px;
}

.form-heading {
  text-align: center;
  margin-bottom: 15px;
  margin-top: 30px;
  font-size: 18px;
  font-family: "Inter";
  font-weight: 500;
}
.form-heading .checkbox-container {
  font-weight: 500;
  font-family: "Inter";
  display: inline-block;
  color: #140c40;
  font-size: 18px;
}
.attachment-input {
  background-color: #f7f7f7;
  padding: 12px 20px;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
}
.attachment-input input {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  cursor: pointer;
}
.attachment-input p {
  color: #140c40;
  font-family: "Inter";
  font-weight: 300;
  font: 16px;
}

.attachment-input p img {
  display: inline-block;
  vertical-align: middle;
  margin-top: -3px;
}

.floating-form__field.floating-form__field_select {
  position: relative;
  /* z-index: 1; */
}
.floating-form__field.floating-form__field_select::before {
  content: ">";
  position: absolute;
  right: 30px;
  top: 22px;
  height: 0;
  margin: auto;
  line-height: 0;
  transform: rotate(90deg) scale(1, 2);
  font-size: 13px;
  opacity: 0.25;
  font-weight: bold;
  width: 0;
  /* z-index: -1; */
}
/* .floating-form__field.floating-form__field_select .field {
  background-color: transparent;
} */
.floating-form__field.floating-form__field_multiselect .field {
  padding: 0;
}
.floating-form__field.floating-form__field_multiselect .field .select__control.select__control--is-focused {
  border: 0 !important;
  outline: 0;
  box-shadow: 0 0 0 transparent;
}
.floating-form__field.floating-form__field_multiselect .field .select__control {
  background-color: transparent;
  padding: 8px 8px 8px 10px;
  border-radius: 0;
  border: 0;
}
.header-filter {
  padding-top: 1px;
}
.header-filter .header-filter-container {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  margin-top: 100px;
  padding: 25px 0;
}

button.category-btn {
  display: inline-block;
  min-width: 200px;
  text-align: left;
  line-height: 20px;
  padding: 10px 15px;
  background-color: transparent;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Inter";
  font-weight: 500;
  color: #140c40;
}
button.category-btn.reset {
  min-width: auto;
  max-width: 130px;
  width: 100%;
}
button.category-btn img {
  margin: -3px 5px 0 0;
  vertical-align: middle;
  display: inline-block;
}
button.filter-btn {
  display: inline-block;
  min-width: 200px;
  text-align: left;
  line-height: 20px;
  padding: 10px 15px;
  background-color: transparent;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Inter";
  font-weight: 500;
  color: #140c40;
}
button.filter-btn img {
  margin: -3px 5px 0 0;
  vertical-align: middle;
  display: inline-block;
}
.category-con,
.filter-con,
.reset-con {
  display: inline-block;
  margin-right: 15px;
  position: relative;
}

.category-dropdown {
  position: absolute;
  left: 0;
  top: 102%;
  min-width: 260px;
  border-radius: 5px;
  border: 1px solid #ddd;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.1);
  padding: 15px 20px;
  z-index: 100;
  background-color: #fff;
  visibility: hidden;
}
.category-dropdown ul li {
  border-bottom: 1px solid #ddd;
}
.category-dropdown ul li button {
  width: 100%;
  cursor: pointer;
  display: block;
  background-color: transparent;
  font-size: 14px;
  border: 0;
  position: relative;
  text-align: left;
  padding: 10px 30px;
  font-family: "Inter";
  font-weight: 500;
  min-height: 40px;
  color: #140c40;
}
.category-dropdown ul li button img {
  margin: -3px 5px 0 0;
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  top: 10px;
  left: 0;
}
.category-dropdown ul li button .checkbox-container {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  padding: 0px;
}
.category-dropdown ul li button .checkbox-container .checkmark {
  left: auto;
  right: 0;
  top: 10px;
}
.category-dropdown .category-submit {
  text-align: right;
  margin-top: 15px;
}
.category-dropdown .category-submit .btn {
  padding: 12px 24px 10px;
  font-size: 14px;
}
.srv-validation-message {
  color: red;
  font-size: 12px;
  text-align: center;
  margin-top: 2px;
}
.error-msg,
.req-msg {
  color: red;
  font-size: 12px;
  text-align: center;
  margin-top: 5px;
}
.category-dropdown.show {
  visibility: visible;
}
.hero.--no-padding-bottom .hero__inner.cat-padding {
  padding-top: 50px;
}
.more-filter-container {
  /* background-color: #eee; */
  padding: 25px 0;
  border-bottom: 1px solid #ddd;
}

.tab-btn {
  margin-top: 15px;
}
.tab-btn .btn {
  padding-top: 12px;
  padding-bottom: 10px;
  font-family: "Inter";
  font-weight: 400;
}
.tab-hr {
  border: 0;
  border-bottom: 1px solid #ddd;
  margin: 0 0 15px 0;
}
.tab-content {
  max-width: 320px;
}

.tab-content .floating-form__field.floating-form__field_select {
  background: #fff;
  border-radius: 25px;
}

.tabs-ul li {
  display: inline-block;
  margin-right: 25px;
}
.tabs-ul li button {
  background-color: transparent;
  border: 0;
  padding: 5px 10px 15px;
  position: relative;
  display: block;
  color: #140c40;
  font-family: "Inter";
  font-weight: 400;
  line-height: 20px;
}
.tabs-ul li button.active {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}
.tabs-ul li button.active::before {
  content: "";
  width: 60%;
  height: 2px;
  background-color: #4760ff;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  font-family: "Inter";
  font-weight: 400;
}
.tabs-ul li button .checkbox-container {
  color: #140c40;
  font-family: "Inter";
  font-weight: 400;
  line-height: 20px;
}

.loader-div {
  padding: 25px;
  text-align: center;
}

.loader-div img {
  width: 300px;
  margin: auto;
}
.tabs-ul {
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}
.floating-form__field .react-datepicker-wrapper {
  display: block;
}
.duplicate-div {
  background-color: #fff;
  border: 1px solid #eee;
  margin-bottom: 15px;
  padding: 25px 15px;
  border-radius: 25px;
}
.duplicate-div .floating-form__field:last-child {
  margin-bottom: 0;
}
.text-right {
  text-align: right;
}
button.btn.btn-add img {
  display: inline-block;
  vertical-align: middle;
  margin-top: -3px;
  margin-right: 10px;
  width: 18px;
  height: 18px;
}
button.btn.btn-add {
  background-color: #eee;
  font-family: "Inter";
  color: #140c40;
  font-size: 14px;
  font-weight: 500;
  padding: 5px 10px 5px 5px;
  margin-bottom: 25px;
  border: 1px solid #eee;
  border-radius: 0;
}
button.btn.btn-add.remove {
  margin-bottom: 0;
  color: red;
  background-color: #ddd;
  border-color: #ddd;
}
.attachment-remove {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 5px 35px 5px 10px;
  border-radius: 14px;
  display: inline-block;
  position: relative;
  height: 28px;
  margin-top: 5px;
}
.attachment-remove p {
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}
.attachment-remove button {
  position: absolute;
  right: 1px;
  top: 1px;
  background: #ddd;
  border-radius: 50%;
  border: 0;
  font-size: 18px;
  font-weight: bold;
  line-height: 18px;
  width: 24px;
  height: 24px;
  text-align: center;
  cursor: pointer;
}
.input-label1 {
  position: absolute;
  left: 20px;
  top: 17px;
  font-size: 14px;
  padding: 0 2px;
  transition: all 0.2s ease-in;
  font-family: "Inter";
  color: #140c40;
  cursor: text;
}
.floating-form__field .field:not(:placeholder-shown) + .input-label1,
.floating-form__field .field:focus + .input-label1 {
  position: absolute;
  top: -8px;
  left: 20px;
  background: #fff;
}
.input-label1 span {
  color: red;
}
.floating-form__field.floating-form__field_select .input-label1,
.floating-form__field-date .input-label1 {
  position: absolute;
  top: -8px;
  left: 20px;
  background: #fff;
}
.floating-form__field.floating-form__field_multiselect .field:not(:placeholder-shown) + .input-label1,
.floating-form__field.floating-form__field_multiselect .field:focus + .input-label1 {
  /* top: -6px; */
}

.floating-form__field {
  position: relative;
}

/* msme card */
.msme-card {
  height: 100%;
}
.msme-card-subheading {
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 5px;
  opacity: 0.8;
  font-family: "Inter";
  font-weight: 400;
}
.msme-subheading {
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 10px;
  opacity: 0.8;
  font-family: "Inter";
  font-weight: 400;
}
.msme-date {
  font-size: 14px;
  text-transform: none;
  margin-bottom: 25px;
  opacity: 0.8;
  font-family: "Inter";
  font-weight: 400;
}
.msme-card-heading {
  font-size: 22px;
  line-height: 26px;
  margin-bottom: 15px;
  font-family: "Inter";
  font-weight: 500;
}
.msme-heading {
  font-size: 28px;
  line-height: 34px;
  margin-bottom: 10px;
  font-family: "Inter";
  font-weight: 500;
}
.msme-card-image {
  background-position: center center;
  background-size: cover;
  height: 240px;
  background-repeat: no-repeat;
}
.msme-card a {
  display: block;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}
.mb40 {
  margin-bottom: 40px;
}
.msme-para {
  margin-top: 40px;
  margin-bottom: 40px;
}
.msme-para p {
  margin-bottom: 20px;
  line-height: 20px;
}
.half-div-500 {
  max-width: 500px;
}
.go-back-static {
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  font-size: 14px;
  display: inline-block;
  padding: 8px 10px 7px;
  border: 2px solid #888;
  border-radius: 25px;
  margin-bottom: 10px;
}
.go-back-static img {
  display: inline-block;
  vertical-align: middle;
  margin-top: -3px;
  margin-right: 5px;
  width: 18px;
  height: 18px;
}
.site-footer {
  z-index: 105;
  position: relative;
}
.submit-fixed {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  padding: 10px 15px 8px;
  z-index: 100;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.3);
}
.submit-fixed .submit-req {
  margin: 0;
}
.submit-fixed .submit-req .btn {
  padding: 8px 25px;
  font-size: 14px;
}
.submit-fixed.is-fixed {
  display: block;
}
#err-msg12,.err-msg-21 {
  color: red;
  font-size: 14px;
  margin-top: 2px;
}

@media screen and (max-width: 992px) {
  .custom-col-3 {
    max-width: 50%;
    flex: 0 0 50%;
  }
  .business-slider .slick-next,
  .business-slider .slick-prev {
    width: 25px;
    height: 25px;
  }
}
@media screen and (max-width: 630px) {
  .custom-col-3 {
    max-width: 100%;
    flex: 0 0 100%;
  }
  button.category-btn {
    min-width: 130px;
    padding: 10px;
  }
  .category-con,
  .filter-con,
  .reset-con {
    margin-right: 0;
  }
  .category-con {
    margin-right: 10px;
  }
  .reset-con {
    width: 100%;
    display: block;
    margin-top: 10px;
  }

  /* .hero__inner.cat-padding .heading-xl,
  .text-lockup.--xlarge .text-lockup__heading {
    font-family: "Inter";
    font-weight: 300;
    font-size: 25px;
    line-height: 1.15;
  } */
  .business-card .business-card-heading {
    font-size: 16px;
  }
  .half-div {
    width: 100%;
    float: none;
    margin-bottom: 15px;
  }
  .half-div.half-div-half {
    width: 50%;
    float: left;
    margin-bottom: 0;
    text-align: left;
  }
  .btn-request {
    max-width: 220px;
  }
  .form-heading .checkbox-container {
    font-size: 14px;
  }
}
h1.hero__heading.heading-xl.u-text-align-center.js-underline-container.js-toggle-on-scroll.--is-in-view.mx-wid {
  max-width: 820px;
  margin: auto;
  width: 100%;
}
